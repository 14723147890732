import { JsonType } from "../../../midgard.js";
import ArrayOfStrings from "../../../components/Common/ArrayOfStrings";
import ArrayOfKeyValuePairs from "../../../components/Common/ArrayOfKeyValuePairs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faKey,
    faInfoCircle,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import { useMemo } from "react";

export default function ExternalEntitiesProperties(props) {

    const setValidConstantValuesOnImport = (valuesArray) => {
        props.entitiesDispatch({
            type: "setEntitiesPropertyValue", 
            payload: {
                index: props.index, 
                property: "validConstantValuesOnImport", 
                value: valuesArray
            }
        });
    };

    const setPropertyKeyOptions = (valuesArray) => {
        props.entitiesDispatch({
            type: "setEntitiesPropertyValue", 
            payload: {
                index: props.index, 
                property: "propertyKeyOptions", 
                value: valuesArray
            }
        });
    };

    const setLinkedPropertyNames = (valuesArray) => {
        props.entitiesDispatch({
            type: "setEntitiesPropertyValue", 
            payload: {
                index: props.index, 
                property: "linkedPropertyNames", 
                value: valuesArray
            }
        });
    };

    const allPropertyNamesExceptMyself = useMemo(() => {
        const allPropertyNamesCopy = [...props.allPropertyNames];
        allPropertyNamesCopy.splice(props.allPropertyNames.indexOf(props.entityProperty.name),1);
        return allPropertyNamesCopy;
    }, [
        props.allPropertyNames,
        props.entityProperty.name
    ]);

    return (
        <div className="mappedColumn shadow border rounded mb-4">
            <div className="flex bg-gray-300 rounded-t">
                <div className="flex-1 text-xl p-2 font-bold">{props.entityProperty.name}</div>
                <div>
                    <button className={`${ props.entityProperty.isKey === true ? "bg-green-700 hover:bg-green-900" : "bg-gray-400 hover:bg-gray-600" } text-white font-bold py-1 px-4 rounded text-sm m-2`} type="button" onClick={() => {props.entitiesDispatch({type: "setEntitiesPropertyValue", payload: {index: props.index, property: "isKey", value: !props.entityProperty.isKey}});}}><FontAwesomeIcon icon={faKey} /></button>
                    <button className="bg-red-700 hover:bg-red-900 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {props.entitiesDispatch({type: "deleteEntitiesProperty", payload: {index: props.index}});}}><FontAwesomeIcon icon={faTrash} /></button>
                </div>
            </div>
            <div className="grid gap-2 m-4" style={{gridTemplateColumns: "auto 1fr"}}>
                <label className="p-2 text-grey-800 font-bold align-middle" htmlFor={`name-${ props.index }`}>
                    Property Name
                </label>
                <input name={`name-${ props.index }`} id={`name-${ props.index }`} type='text' value={props.entityProperty.name} onChange={(e) => {props.entitiesDispatch({type: "setEntitiesPropertyValue", payload: {index: props.index, property: "name", value: e.target.value}});}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" placeholder="Required" />
                <div className="p-2 text-grey-800 font-bold align-middle">
                    Options
                </div>
                <div>
                    <select name={`jsonType-${ props.index }`} id={`jsonType-${ props.index }`} value={props.entityProperty.jsonType} onChange={(e) => {props.entitiesDispatch({type: "setEntitiesPropertyValue", payload: {index: props.index, property: "jsonType", value: e.target.value}});}} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                        {
                            JsonType.map((jsonType) => <option value={jsonType} key={jsonType}>{jsonType}</option>)
                        }
                    </select>
                    <ul className="inline ml-4">
                        <li className="inline">
                            <label className="whitespace-nowrap align-middle" htmlFor={`isRequired-${ props.index }`}>
                                <input type="checkbox" name={`isRequired-${ props.index }`} id={`isRequired-${ props.index }`} value={props.entityProperty.isRequired || false} checked={props.entityProperty.isRequired ? "checked" : ""} onChange={(e) => {props.entitiesDispatch({type: "setEntitiesPropertyValue", payload: {index: props.index, property: "isRequired", value: e.target.checked}});}} className="mr-2 align-middle" />
                                <span className="text-grey-800 align-middle">Required</span>
                            </label>
                        </li>
                        <li className="inline ml-3">
                            <label className="whitespace-nowrap align-middle" htmlFor={`jsonTypeIsNullable-${ props.index }`}>
                                <input type="checkbox" name={`jsonTypeIsNullable-${ props.index }`} id={`jsonTypeIsNullable-${ props.index }`} value={props.entityProperty.jsonTypeIsNullable || false} checked={props.entityProperty.jsonTypeIsNullable ? "checked" : ""} onChange={(e) => {props.entitiesDispatch({type: "setEntitiesPropertyValue", payload: {index: props.index, property: "jsonTypeIsNullable", value: e.target.checked}});}} className="mr-2 align-middle" />
                                <span className="text-grey-800 align-middle">Nullable</span>
                            </label>
                        </li>
                        <li className="inline ml-3">
                            <label className="whitespace-nowrap align-middle" htmlFor={`allowUrlSubstitution-${ props.index }`}>
                                <input type="checkbox" name={`allowUrlSubstitution-${ props.index }`} id={`allowUrlSubstitution-${ props.index }`} value={props.entityProperty.allowUrlSubstitution || false} checked={props.entityProperty.allowUrlSubstitution ? "checked" : ""} onChange={(e) => {props.entitiesDispatch({type: "setEntitiesPropertyValue", payload: {index: props.index, property: "allowUrlSubstitution", value: e.target.checked}});}} className="mr-2 align-middle" />
                                <span className="text-grey-800 align-middle">Allow URL Substitution</span>
                            </label>
                        </li>
                        <li className="inline ml-3">
                            <label className="whitespace-nowrap align-middle" htmlFor={`requiresPropertyKeys-${ props.index }`}>
                                <input type="checkbox" name={`requiresPropertyKeys-${ props.index }`} id={`requiresPropertyKeys-${ props.index }`} value={props.entityProperty.requiresPropertyKeys || false} checked={props.entityProperty.requiresPropertyKeys ? "checked" : ""} onChange={(e) => {props.entitiesDispatch({type: "setEntitiesPropertyValue", payload: {index: props.index, property: "requiresPropertyKeys", value: e.target.checked}});}} className="mr-2 align-middle" />
                                <span className="text-grey-800 align-middle">Property Keys</span>
                            </label>
                        </li>
                        {
                            Array.isArray(allPropertyNamesExceptMyself) && allPropertyNamesExceptMyself.length > 0
                                ?   <li className="inline ml-3">
                                        <label className="whitespace-nowrap align-middle" htmlFor={`hasLinkedProperties-${ props.index }`}>
                                            <input type="checkbox" name={`hasLinkedProperties-${ props.index }`} id={`hasLinkedProperties-${ props.index }`} value={props.entityProperty.hasLinkedProperties || false} checked={props.entityProperty.hasLinkedProperties ? "checked" : ""} onChange={(e) => {props.entitiesDispatch({type: "setEntitiesPropertyValue", payload: {index: props.index, property: "hasLinkedProperties", value: e.target.checked}});}} className="mr-2 align-middle" />
                                            <span className="text-grey-800 align-middle">Linked Properties</span>
                                        </label>
                                    </li>
                                : null

                        }
                        <li className="inline ml-3">
                            <label className="whitespace-nowrap align-middle" htmlFor={`hasDisplayNameOverride-${ props.index }`}>
                                <input type="checkbox" name={`hasDisplayNameOverride-${ props.index }`} id={`hasDisplayNameOverride-${ props.index }`} value={props.entityProperty.hasDisplayNameOverride || false} checked={props.entityProperty.hasDisplayNameOverride ? "checked" : ""} onChange={(e) => {props.entitiesDispatch({type: "setEntitiesPropertyValue", payload: {index: props.index, property: "hasDisplayNameOverride", value: e.target.checked}});}} className="mr-2 align-middle" />
                                <span className="text-grey-800 align-middle">Display Name Override</span>
                            </label>
                        </li>
                    </ul>
                </div>
                <div className="p-2 text-grey-800 font-bold align-middle">
                    Import Options
                </div>
                <div className="pt-1.5">
                    <ul className="inline align-middle">
                        <li className="inline">
                            <label className="whitespace-nowrap align-middle" htmlFor={`allowConstantValueOnImport-${ props.index }`}>
                                <input type="checkbox" name={`allowConstantValueOnImport-${ props.index }`} id={`allowConstantValueOnImport-${ props.index }`} value={props.entityProperty.allowConstantValueOnImport || false} checked={props.entityProperty.allowConstantValueOnImport ? "checked" : ""} onChange={(e) => {props.entitiesDispatch({type: "setEntitiesPropertyValue", payload: {index: props.index, property: "allowConstantValueOnImport", value: e.target.checked}});}} className="mr-2 align-middle" />
                                <span className="text-grey-800 align-middle">Allow Constant</span>
                            </label>
                        </li>
                        <li className="inline ml-3">
                            <label className="whitespace-nowrap align-middle" htmlFor={`allowMultipleMappingOnImport-${ props.index }`}>
                                <input type="checkbox" name={`allowMultipleMappingOnImport-${ props.index }`} id={`allowMultipleMappingOnImport-${ props.index }`} value={props.entityProperty.allowMultipleMappingOnImport || false} checked={props.entityProperty.allowMultipleMappingOnImport ? "checked" : ""} onChange={(e) => {props.entitiesDispatch({type: "setEntitiesPropertyValue", payload: {index: props.index, property: "allowMultipleMappingOnImport", value: e.target.checked}});}} className="mr-2 align-middle" />
                                <span className="text-grey-800 align-middle" title="This is typically used for properties in an array that are not part of the vertical hierarchy (like service window overrides) or for things like custom properties.">Allow Multiple Mapping</span>
                            </label>
                            <FontAwesomeIcon className="pl-2 text-grey-800 text-sm font-bold align-middle inline" icon={faInfoCircle} title="This is typically used for properties in an array that are not part of the vertical hierarchy (like service window overrides) or for things like custom properties." />
                        </li>
                        {
                            props.entityProperty.allowConstantValueOnImport
                                ?   <li className="inline ml-3">
                                        <label className="whitespace-nowrap align-middle" htmlFor={`allowMultipleValidConstantValuesOnImport-${ props.index }`}>
                                            <input type="checkbox" name={`allowMultipleValidConstantValuesOnImport-${ props.index }`} id={`allowMultipleValidConstantValuesOnImport-${ props.index }`} value={props.entityProperty.allowMultipleValidConstantValuesOnImport || false} checked={props.entityProperty.allowMultipleValidConstantValuesOnImport ? "checked" : ""} onChange={(e) => {props.entitiesDispatch({type: "setEntitiesPropertyValue", payload: {index: props.index, property: "allowMultipleValidConstantValuesOnImport", value: e.target.checked}});}} className="mr-2 align-middle" />
                                            <span className="text-grey-800 align-middle" title="This is typically used for flag-type enums to create a multiselect like the days of a week.">Allow Multiple Valid Contant Values</span>
                                        </label>
                                        <FontAwesomeIcon className="pl-2 text-grey-800 text-sm font-bold align-middle inline" icon={faInfoCircle} title="This is typically used for flag-type enums to create a multiselect like the days of a week." />
                                    </li>
                                : null
                        }
                    </ul>
                </div>
                {
                    props.entityProperty.hasLinkedProperties
                        ?   <>
                                <label className="p-2 text-grey-800 font-bold align-middle" htmlFor={`propertyKeyOptions-${ props.index }`}>
                                    Linked Property Names
                                </label>
                                <div className="flex-1">
                                    <ArrayOfStrings
                                        arrayOfStrings={props.entityProperty.linkedPropertyNames}
                                        setArrayOfStrings={setLinkedPropertyNames}
                                        selectOptions={allPropertyNamesExceptMyself}
                                    />
                                </div>
                            </>
                        : null
                }
                {
                    props.entityProperty.requiresPropertyKeys
                        ?   <>
                                <label className="p-2 text-grey-800 font-bold align-middle" htmlFor={`propertyKeyOptions-${ props.index }`}>
                                    Property Key Options
                                </label>
                                <div className="flex-1">
                                    <ArrayOfKeyValuePairs
                                        arrayOfKeyValuePairs={props.entityProperty.propertyKeyOptions}
                                        setArrayOfKeyValuePairs={setPropertyKeyOptions}
                                    />
                                </div>
                            </>
                        : null
                }
                {
                    props.entityProperty.allowConstantValueOnImport
                        ?   <>
                                <label className="p-2 text-grey-800 font-bold align-middle" htmlFor={`validConstantValuesOnImport-${ props.index }`}>
                                    Valid Constant Values
                                </label>
                                <div className="flex-1">
                                    <ArrayOfKeyValuePairs
                                        arrayOfKeyValuePairs={props.entityProperty.validConstantValuesOnImport}
                                        setArrayOfKeyValuePairs={setValidConstantValuesOnImport}
                                    />
                                </div>
                            </>
                        : null
                }
                {
                    props.entityProperty.hasDisplayNameOverride
                        ?   <>
                                <label className="p-2 text-grey-800 font-bold align-middle" htmlFor={`displayNameOverride-${ props.index }`}>
                                    Display Name Override
                                </label>
                                <input name={`displayNameOverride-${ props.index }`} id={`displayNameOverride-${ props.index }`} type='text' value={props.entityProperty.displayNameOverride} onChange={(e) => {props.entitiesDispatch({type: "setEntitiesPropertyValue", payload: {index: props.index, property: "displayNameOverride", value: e.target.value}});}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" placeholder="Optional" />
                            </>
                        : null
                }
            </div>
        </div>
    );
}