import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import ErrorTile from "../../Common/ErrorTile";
import DownloadFileButton from "../../Common/DownloadFileButton";
import { useState } from "react";

export default function ProcessTrackingFileModalRow(props) {  

    const [errorMessage, setErrorMessage] = useState();

    const displayFileName = props.filename.startsWith("mip/")
        ? props.filename.substring(props.filename.lastIndexOf("mip/")+4)
        : props.filename;

    return (
        <>
        <div key={`task-chain-file-${ props.taskIndex }-${ props.taskChainFileIndex }-task`} className="contents group">
            <div className="p-2 border group-odd:bg-gray-100">{props.task}</div>
            <div className="p-2 border group-odd:bg-gray-100">
                <div>{props.taskChainFile}</div>
                <div className="text-xs">{displayFileName} <FontAwesomeIcon onClick={() => {navigator.clipboard.writeText(props.filename.substring(props.filename.lastIndexOf("/")+1)).then(() => alert("Copied Filename (without path) to clipboard."));}} icon={faCopy} /></div>
            </div>
            <div className="px-2 border text-center group-odd:bg-gray-100">
                {
                    ["SourceFilePrefix"].includes(props.taskChainFile)
                        ? null
                        :   <DownloadFileButton
                                filename={props.filename}
                                setErrorMessage={setErrorMessage}
                            />
                }
            </div>
        </div>
        {
            errorMessage
                ?   <div key={`task-chain-file-${ props.taskIndex }-${ props.taskChainFileIndex }-error`} className="col-span-3">
                        <ErrorTile message={errorMessage} />
                    </div>
                : null
        }
        </>
    );
}