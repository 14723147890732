import { useEffect, useLayoutEffect, useMemo, useReducer, useState } from "react";
import {
    useNavigate,
    useLocation
} from "react-router-dom";
import { useSelector } from "react-redux";
import {
    formatDuration,
    formatDateAsISO
} from "../../midgard.js";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../features/auth/authSlice";
import UserInformationBanner from "../../components/Common/UserInformationBanner";
import NotFound from "../../components/Common/NotFound";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import ErrorTile from "../../components/Common/ErrorTile";
import MetadataFilter from "../../components/ProcessTracking/MetadataFilter";
import ProcessTrackingFileModal from "../../components/ProcessTracking/ProcessTrackingFileModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCopy,
    faEye,
    faSpinner,
    faFile,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import {
    mipApi,
    useGetJobResultsQuery,
    useGetSettingsQuery,
    useGetProvisionedProcessesQuery
} from "../../services/mip";

export default function ProcessTracking(props) {   
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const processIdentifier = queryParams.get("processIdentifier");
    const offset = (new Date().getTimezoneOffset()) / -60;

    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionProcesses") || privilege.includes("All");
    const canSeedData = privilege.includes("Admin") || privilege.includes("All");

    const [provisionedProcessIdentifier, setProvisionedProcessIdentifier] = useState(processIdentifier ? processIdentifier : undefined);
    const [taskIdentifier, setTaskIdentifier] = useState("");
    const [taskChainInstance, setTaskChainInstance] = useState("");
    const [collectedFiles, setCollectedFiles] = useState();
    const [showFileDetailsModal, setShowFileDetailsModal] = useState();
    const [retrieveFilesJobIdentifier, setRetrieveFilesJobIdentifier] = useState();
    const [isPendingLoading, setIsPendingLoading] = useState(false);
    const [useUTC, setUseUTC] = useState(false);
    const [onlyShowErrors, setOnlyShowErrors] = useState(false);
    const [pagingStart, setPagingStart] = useState(0);
    const pageSize = 1000;

    const {
        data: settingsData,
        error: settingsError,
        isFetching: settingsIsFetching
    } = useGetSettingsQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });

    const {
        data: provisionedProcessData,
        error: provisionedProcessAPIError,
        isFetching: provisionedProcessDataFetching,
    } = useGetProvisionedProcessesQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });

    const {
        data: jobResultsData,
        error: jobResultsAPIError,
        isFetching: jobResultsDataFetching,
        refetch: fetchJobResults,
    } = useGetJobResultsQuery(retrieveFilesJobIdentifier, { skip: !isLoggedIn || !hasPrivilege || !retrieveFilesJobIdentifier });
    
    const [fetchProcessTrackingFilesFunction, { data: processTrackingData, isFetching: processTrackingIsFetching, error: processTrackingError }] = mipApi.endpoints.getProcessTrackingFiles.useLazyQuery();
    // this will always return a Job Identifier
    // so when processTrackingData changes we set the Job Identifier
    // and use the existing of a job identifier as an indicator we are still loading data
    const fetchProcessTrackingFiles = () => {
        setPagingStart(0);
        setCollectedFiles(undefined);
        fetchProcessTrackingFilesFunction({
            provisionedProcessIdentifier,
            taskIdentifier,
            taskChainInstance,
            metadataFilters
        });
    };

    useEffect(() => {
        if (
            (settingsError && settingsError.status === 401) ||
            (provisionedProcessAPIError && provisionedProcessAPIError.status === 401) ||
            (jobResultsAPIError && jobResultsAPIError.status === 401)||
            (processTrackingError && processTrackingError.status === 401)
        ) {
            window.location.href="/login";
        }
    }, [settingsError, provisionedProcessAPIError, jobResultsAPIError, processTrackingError]);

    const processesByProcessId = useMemo(() => {
        const processes = {};
        if (Array.isArray(provisionedProcessData)) {
            for (const process of provisionedProcessData) {
                processes[process.Identifier] = process;
            }
        }
        return processes;
    }, [provisionedProcessData]);

    const selectedProcess = useMemo(() => {
        let process;
        if (provisionedProcessIdentifier && processesByProcessId[provisionedProcessIdentifier]) {
            process = processesByProcessId[provisionedProcessIdentifier];
        }
        return process;
    }, [
        processesByProcessId,
        provisionedProcessIdentifier
    ]);

    const tasksByTaskId = useMemo(() => {
        const tasks = {};
        if (Array.isArray(provisionedProcessData)) {
            for (const process of provisionedProcessData) {
                for (const taskName of Object.keys(process.TaskIdentifiers)) {
                    tasks[process.TaskIdentifiers[taskName]] = taskName;
                }
            }
        }
        return tasks;
    }, [
        provisionedProcessData
    ]);

    useLayoutEffect(() => {
        if (Array.isArray(provisionedProcessData) && provisionedProcessData.length > 0) {
            if (provisionedProcessIdentifier === undefined) {
                setProvisionedProcessIdentifier("");
            }
            if (provisionedProcessIdentifier && taskIdentifier !== "" && !Object.values(processesByProcessId[provisionedProcessIdentifier].TaskIdentifiers).includes(taskIdentifier)) {
                setTaskIdentifier("");
            }
        }
    }, [
        provisionedProcessData,
        provisionedProcessIdentifier,
        processesByProcessId,
        taskIdentifier
    ]);

    useLayoutEffect(() => {
        if (Array.isArray(provisionedProcessData) && provisionedProcessData.length > 0) {
            if (provisionedProcessIdentifier === undefined) {
                setProvisionedProcessIdentifier("");
            }
            if (provisionedProcessIdentifier && taskIdentifier !== "" && !Object.values(processesByProcessId[provisionedProcessIdentifier].TaskIdentifiers).includes(taskIdentifier)) {
                setTaskIdentifier("");
            }
        }
    }, [
        provisionedProcessData,
        provisionedProcessIdentifier,
        processesByProcessId,
        taskIdentifier
    ]);

    const firstTaskResulMetadataKey = Array.isArray(settingsData?.TaskResultMetadataKeys) && settingsData.TaskResultMetadataKeys.length > 0 ? settingsData.TaskResultMetadataKeys[0] : undefined;

    const metadataFiltersReducer = (state, action) => {
        const stateCopy = state.map((obj) => ({...obj}));
        switch (action.type) {
            case "addFilter":
                if (firstTaskResulMetadataKey) {
                    stateCopy.push({
                        metadataKey: firstTaskResulMetadataKey,
                        value: ""
                    });
                }
                break;
            case "setFilterValue":
                stateCopy[action.payload.index][action.payload.property] = action.payload.value;
                break;
            case "deleteFilter":
                stateCopy.splice(action.payload.index, 1);
                break;
            default:
                throw new Error("unknown action.type in metadataFiltersReducer");
        }
        return stateCopy;
    };

    const [metadataFilters, metadataFiltersDispatch] = useReducer(metadataFiltersReducer, []);

    useEffect(() => {
        document.title = "Administration - Process Tracking";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    useLayoutEffect(() => {
        if (processTrackingData?.Data) {
            setIsPendingLoading(true);
            setTimeout(() => {
                setRetrieveFilesJobIdentifier(processTrackingData.Data);
                setIsPendingLoading(false);
            }, 4000);
        }
    }, [processTrackingData]);

    useLayoutEffect(() => {
        if (Array.isArray(jobResultsData?.Data)) {
            // this is going to get everything. We probably want paging here so 
            // we'll want to use the same system the Logs page does, maybe.
            setCollectedFiles(jobResultsData.Data);
            setRetrieveFilesJobIdentifier(undefined);
        }
    }, [jobResultsData]);


    const filesToDisplay = [];
    if (Array.isArray(collectedFiles)) {
        for (const file of collectedFiles) {
            if (!onlyShowErrors || (onlyShowErrors && (file.HasFailedRecords || file.Success === false))) {
                filesToDisplay.push(file);
            }
        }
    }

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white">
            <div className="p-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="pb-4 flex">
                        <div className="text-2xl flex-1 p-1"><FontAwesomeIcon icon={faFile} /> Process Tracking</div>
                        {canSeedData && <button className="inline bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => navigate("/processtracking/seed")}>Go to Seeding Form</button>}
                    </div>
                    {
                        provisionedProcessAPIError || settingsError
                            ? <ErrorTile message={ provisionedProcessAPIError || settingsError } />
                            : provisionedProcessDataFetching || settingsIsFetching
                                ? <LoadingSpinner text={"Loading Prerequisite Data"} />
                                : <>
                                    <div className="border p-2">
                                        <div>
                                            <div className="my-2">
                                                <div className="mb-4">
                                                    <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="provisionedProcessIdentifier">
                                                        Provisioned Process
                                                    </label>
                                                </div>
                                                <select id="provisionedProcessIdentifier" name="provisionedProcessIdentifier" value={provisionedProcessIdentifier} onChange={(e) => setProvisionedProcessIdentifier(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                    <option key="none" value="">All</option>
                                                    {
                                                        provisionedProcessData.map((process) => <option value={process.Identifier} key={process.Identifier}>{process.Name}</option>)
                                                    }
                                                </select>
                                            </div>
                                            {
                                                provisionedProcessIdentifier && provisionedProcessIdentifier !== ""
                                                    ?   <div className="my-2">
                                                            <div className="mb-4">
                                                                <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="taskIdentifier">
                                                                    Task
                                                                </label>
                                                            </div>
                                                            <select id="taskIdentifier" name="taskIdentifier" value={taskIdentifier} onChange={(e) => setTaskIdentifier(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                                <option key="none" value="">All</option>
                                                                {
                                                                    selectedProcess?.TaskIdentifiers && Object.keys(selectedProcess.TaskIdentifiers).map((taskIdentifierKey) => <option value={selectedProcess.TaskIdentifiers[taskIdentifierKey]} key={selectedProcess.TaskIdentifiers[taskIdentifierKey]}>{taskIdentifierKey}</option>)
                                                                }
                                                            </select>
                                                        </div>
                                                    :   null
                                            }
                                            <div className="my-2">
                                                <div className="mb-4">
                                                    <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="taskChainInstance">
                                                        Task Chain Instance
                                                    </label>
                                                </div>
                                                <input id="taskChainInstance" type="text" value={taskChainInstance} onChange={(e) => setTaskChainInstance(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                            </div>
                                            <div className="my-2">
                                                <div className="mb-4">
                                                    <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="taskChainInstance">
                                                        Metadata Filters
                                                    </label>
                                                </div>
                                                {
                                                    Array.isArray(metadataFilters)
                                                        ? metadataFilters.length === 0
                                                            ? <div>No filters</div>
                                                            : metadataFilters.map((filter, index) => <MetadataFilter key={index} filterIndex={index} filter={filter} metadataFiltersDispatch={metadataFiltersDispatch} settingsData={settingsData} />)
                                                        : null
                                                }
                                                <div className="mb-4 flex justify-end text-sm">
                                                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => {metadataFiltersDispatch({type: "addFilter", payload: undefined});}}><FontAwesomeIcon icon={faPlus} /> Add a Metadata Filter</button>
                                                </div>
                                            </div>
                                            <div className="m-1 mb-2 pl-2">
                                                <label className="align-middle" htmlFor="useUTC">
                                                    <input type="checkbox" name="useUTC" id="useUTC" value={useUTC} checked={useUTC ? "checked" : ""} onChange={(e) => {setUseUTC(e.target.checked);}} className="mr-2 align-middle" />
                                                    <span className="text-grey-800 align-middle text-sm font-bold">Use UTC</span>
                                                </label>
                                            </div>
                                            <div className="m-1 mb-2 pl-2">
                                                <label className="align-middle" htmlFor="onlyShowErrors">
                                                    <input type="checkbox" name="onlyShowErrors" id="onlyShowErrors" value={onlyShowErrors} checked={onlyShowErrors ? "checked" : ""} onChange={(e) => {setOnlyShowErrors(e.target.checked);}} className="mr-2 align-middle" />
                                                    <span className="text-grey-800 align-middle text-sm font-bold">Only Show Errors</span>
                                                </label>
                                            </div>
                                            <div className="flex justify-evenly">
                                                <button data-test-id="fetch-process-tracking-files-button" disabled={isPendingLoading || retrieveFilesJobIdentifier || processTrackingIsFetching} className="inline bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => fetchProcessTrackingFiles()}>{isPendingLoading || retrieveFilesJobIdentifier || processTrackingIsFetching ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : <span>Retrieve Files</span>}</button>
                                            </div>
                                        </div>
                                    </div>
                                    { 
                                        processTrackingError || jobResultsAPIError
                                            ?   <>
                                                    <ErrorTile message={processTrackingError || jobResultsAPIError} />
                                                    {
                                                        jobResultsAPIError && retrieveFilesJobIdentifier
                                                            ? <div><span onClick={() => {fetchJobResults(retrieveFilesJobIdentifier);}}>Click here</span> to retry job ID #{ retrieveFilesJobIdentifier } </div>
                                                            : null
                                                    }
                                                </>
                                            : null
                                    }
                                    {
                                        isPendingLoading || jobResultsDataFetching || processTrackingIsFetching || retrieveFilesJobIdentifier
                                            ? <LoadingSpinner text="Fetching Process Tracking Files" />
                                            : collectedFiles === undefined
                                                ? <div data-test-id="no-log-entries-found" className="text-center pt-4">Use the search above to find process tracking files.</div>
                                                : Object.keys(filesToDisplay).length === 0
                                                    ?   <div data-test-id="no-log-entries-found" className="text-center pt-4">No files to display.</div>
                                                    :   <>
                                                            <div className="text-center mt-4">
                                                                <span className="mr-2">Showing rows {pagingStart+1}-{pagingStart+pageSize < filesToDisplay.length ? pagingStart+pageSize : filesToDisplay.length} of {filesToDisplay.length}.</span>
                                                                {
                                                                    filesToDisplay.length > pageSize
                                                                        ?   <>
                                                                            {
                                                                                filesToDisplay.length > pageSize && filesToDisplay.map((_, index) => (
                                                                                    <>
                                                                                    {
                                                                                        (index === 0 || index % pageSize === 0) && <span key={index} className="cursor-pointer hover:underline text-gray-600 mr-2" onClick={() => {setPagingStart(index);}}>{(index / pageSize)+1}</span>
                                                                                    }
                                                                                    </>
                                                                                ))
                                                                            }
                                                                            </>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div className="mt-4 grid" style={{gridTemplateColumns: `repeat(${ provisionedProcessIdentifier === "" ? 6 : 5 }, auto) 7rem`}}>
                                                                <div className="text-center font-bold px-2 border">Start { useUTC ? "UTC" : `UTC${ offset >= 0 ? "+" : "" }${ offset }` }</div>
                                                                <div className="text-center font-bold px-2 border">End { useUTC ? "UTC" : `UTC${ offset >= 0 ? "+" : "" }${ offset }` }</div>
                                                                <div className="text-center font-bold px-2 border">Duration</div>
                                                                <div className="text-center font-bold px-2 border">TCI</div>
                                                                {
                                                                    provisionedProcessIdentifier === ""  && <div className="text-center font-bold px-2 border">Process</div>
                                                                }
                                                                <div className="text-center font-bold px-2 border">Task</div>
                                                                <div className="text-center font-bold px-2 border">View</div>
                                                                {
                                                                    filesToDisplay.slice(pagingStart,pagingStart+pageSize).map((file, index) => (
                                                                        <div className="contents group" key={`key-${ index }`}>
                                                                            {   <>
                                                                                    <div className={`p-2 border ${ !file.Success ? "group-odd:bg-red-200 group-even:bg-red-100" : file.HasFailedRecords ? "group-odd:bg-orange-200 group-even:bg-orange-100" : "group-odd:bg-gray-100" }`}>{useUTC ? file.StartTime : formatDateAsISO(new Date(`${ file.StartTime }Z`))}</div>
                                                                                    <div className={`p-2 border ${ !file.Success ? "group-odd:bg-red-200 group-even:bg-red-100" : file.HasFailedRecords ? "group-odd:bg-orange-200 group-even:bg-orange-100" : "group-odd:bg-gray-100" }`}>{useUTC ? file.EndTime : formatDateAsISO(new Date(`${ file.EndTime }Z`))}</div>
                                                                                    <div className={`p-2 border ${ !file.Success ? "group-odd:bg-red-200 group-even:bg-red-100" : file.HasFailedRecords ? "group-odd:bg-orange-200 group-even:bg-orange-100" : "group-odd:bg-gray-100" }`}>{formatDuration(new Date(file.EndTime) - new Date(file.StartTime))}</div>
                                                                                    <div className={`p-2 border ${ !file.Success ? "group-odd:bg-red-200 group-even:bg-red-100" : file.HasFailedRecords ? "group-odd:bg-orange-200 group-even:bg-orange-100" : "group-odd:bg-gray-100" }`}>{file.TaskChainInstance} <FontAwesomeIcon onClick={() => {navigator.clipboard.writeText(file.TaskChainInstance).then(() => alert(`Copied TCI ${ file.TaskChainInstance } to clipboard.`));}} className="text-sm px-2 text-gray-800" icon={faCopy} /></div>
                                                                                    {
                                                                                        provisionedProcessIdentifier === ""  && <div className={`p-2 border ${ !file.Success ? "group-odd:bg-red-200 group-even:bg-red-100" : file.HasFailedRecords ? "group-odd:bg-orange-200 group-even:bg-orange-100" : "group-odd:bg-gray-100" }`}>{ processesByProcessId[file.ProvisionedProcessIdentifier] ? processesByProcessId[file.ProvisionedProcessIdentifier].Name : "Unknown" }</div>
                                                                                    }
                                                                                    <div className={`p-2 border ${ !file.Success ? "group-odd:bg-red-200 group-even:bg-red-100" : file.HasFailedRecords ? "group-odd:bg-orange-200 group-even:bg-orange-100" : "group-odd:bg-gray-100" }`}>{ tasksByTaskId[file.TaskIdentifier] ? tasksByTaskId[file.TaskIdentifier] : file.TaskIdentifier }</div>
                                                                                    <div className={`px-2 border text-center ${ !file.Success ? "group-odd:bg-red-200 group-even:bg-red-100" : file.HasFailedRecords ? "group-odd:bg-orange-200 group-even:bg-orange-100" : "group-odd:bg-gray-100" }`}><button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => setShowFileDetailsModal(file.Filename)}><FontAwesomeIcon className="w-auto" icon={faEye} /></button></div>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </>
                                        
                                    }
                                    </>
                    }
                </div>
                {
                    Array.isArray(collectedFiles) && showFileDetailsModal !== undefined
                        ? collectedFiles.map((file, index) => (
                            showFileDetailsModal === file.Filename
                                ?   <ProcessTrackingFileModal 
                                        key={index}
                                        title={file.Filename}
                                        filename={file.Filename}
                                        useUTC={useUTC}
                                        closeModal={setShowFileDetailsModal}
                                        processesByProcessId={processesByProcessId}
                                        tasksByTaskId={tasksByTaskId}
                                    />
                                : null
                        ))
                        : null
                }
            </div>
        </div>
        </>
    );
}