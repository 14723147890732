import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCopy,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ErrorTile from "../../Common/ErrorTile";
import ProcessTrackingFileModalRow from "../ProcessTrackingFileModalRow/index.js";
import {
    formatDuration,
    formatDateAsISO
} from "../../../midgard.js";
import {
    useGetProcessTrackingFileQuery,
    useGetSettingsQuery
} from "../../../services/mip";

export default function ProcessTrackingFileModal(props) {  
    const navigate = useNavigate();
    
    const {
        data: processTrackingFileData,
        error: processTrackingFileError,
        isFetching: processTrackingFileLoading
    } = useGetProcessTrackingFileQuery(props.filename);

    const {
        data: settingsData,
    } = useGetSettingsQuery(undefined);

    useEffect(() => {
        if (processTrackingFileError && processTrackingFileError.status === 401) {
            navigate("/login");
        }
    }, [navigate, processTrackingFileError]);

    const taskChainRows = [];
    if (processTrackingFileData?.Data?.TaskChainedData?.TaskChainFiles) {
        for (const task of Object.keys(processTrackingFileData.Data.TaskChainedData.TaskChainFiles)) {
            for (const taskChainFile of Object.keys(processTrackingFileData.Data.TaskChainedData.TaskChainFiles[task])) {
                taskChainRows.push(
                    <ProcessTrackingFileModalRow
                        key={`${ task }-${ taskChainFile }`}
                        task={task}
                        taskChainFile={taskChainFile}
                        filename={processTrackingFileData.Data.TaskChainedData.TaskChainFiles[task][taskChainFile].ObjectKey}
                    />
                );
            }
        }
    }

    return (
        <div className="opacity-100 fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
            <div className="absolute w-full h-full bg-gray-500 opacity-95"></div>
            <div className="fixed w-full h-full z-50 overflow-y-auto">
                <div className="container mx-auto w-4/5 h-auto text-left m-4 bg-white rounded-lg">
                    <div className="bg-purple-800 text-white text-2xl rounded-t-lg ">
                        <div className="flex">
                            <div className="flex-1 p-2">Process Tracking File</div>
                            <div className="flex-initial p-2"><FontAwesomeIcon data-test-id="api-key-value-modal-close-link" className="hover:cursor-pointer" icon={faTimesCircle} onClick={props.closeModal} /></div>
                        </div>
                    </div>
                    { 
                        processTrackingFileError
                            ? <ErrorTile message={processTrackingFileError} />
                            : processTrackingFileLoading
                                ? <LoadingSpinner width="w-full" text={"Loading Process Tracking File Data"} />
                                : !processTrackingFileData || !processTrackingFileData.hasOwnProperty("Data")
                                    ?   <div className="text-center p-4">Unable to load process tracking file</div>
                                    :   <div className="m-4 pb-4">
                                            <div className="grid" style={{gridTemplateColumns: "fit-content(20%) auto"}}>
                                                <div className="px-2 border font-bold">Customer</div>
                                                <div className="px-2 border"><span data-test-id="customer-name">{settingsData.CustomerName}</span> <FontAwesomeIcon className="hover:cursor-pointer text-gray-600 ml-1" icon={faCopy} onClick={() => {navigator.clipboard.writeText(settingsData.CustomerIdentifier).then(() => alert(`Copied Customer Identifier ${ settingsData.CustomerIdentifier } to clipboard.`));}} /></div>
                                                <div className="px-2 border font-bold">Process</div>
                                                <div className="px-2 border">{ props.processesByProcessId.hasOwnProperty(processTrackingFileData.Data.ProvisionedProcessIdentifier) ? props.processesByProcessId[processTrackingFileData.Data.ProvisionedProcessIdentifier].Name : "Unknown" }<FontAwesomeIcon onClick={() => {navigator.clipboard.writeText(processTrackingFileData.Data.ProvisionedProcessIdentifier).then(() => alert(`Copied Process Identifier ${ processTrackingFileData.Data.ProvisionedProcessIdentifier } to clipboard.`));}} className="text-lg px-2" icon={faCopy} /></div>
                                                <div className="px-2 border font-bold">Task</div>
                                                <div className="px-2 border">{ props.tasksByTaskId.hasOwnProperty(processTrackingFileData.Data.TaskIdentifier) ? props.tasksByTaskId[processTrackingFileData.Data.TaskIdentifier] : "Unknown" }</div>
                                                <div className="px-2 border font-bold">TCI</div>
                                                <div className="px-2 border">{processTrackingFileData.Data.TaskChainInstance} <FontAwesomeIcon onClick={() => {navigator.clipboard.writeText(processTrackingFileData.Data.TaskChainInstance).then(() => alert("Copied TCI to clipboard."));}} className="text-lg px-2" icon={faCopy} /></div>
                                                <div className="px-2 border font-bold">Filename</div>
                                                <div className="px-2 border">{props.filename} <FontAwesomeIcon onClick={() => {navigator.clipboard.writeText(props.filename).then(() => alert("Copied file name to clipboard."));}} className="text-lg px-2" icon={faCopy} /></div>
                                                <div className="px-2 border font-bold">Start Time</div>
                                                <div className="px-2 border">{ props.useUTC ? processTrackingFileData.Data.StartTime : formatDateAsISO(new Date(processTrackingFileData.Data.StartTime)) }</div>
                                                <div className="px-2 border font-bold">End Time</div>
                                                <div className="px-2 border">{ props.useUTC ? processTrackingFileData.Data.EndTime : formatDateAsISO(new Date(processTrackingFileData.Data.EndTime)) }</div>
                                                <div className="px-2 border font-bold">Duration</div>
                                                <div className="px-2 border">{ formatDuration(new Date(processTrackingFileData.Data.EndTime) - new Date(processTrackingFileData.Data.StartTime))}</div>
                                                <div className="px-2 border font-bold">Has Failed Records</div>
                                                <div className="px-2 border">{ processTrackingFileData.Data?.TaskChainedData?.HasFailedRecords === true ? "Yes" : "No" }</div>
                                                <div className="px-2 border font-bold">Error Message</div>
                                                <div className="px-2 border">{ processTrackingFileData.Data.ErrorMessage }</div>
                                                {
                                                    processTrackingFileData?.Data?.TaskChainedData?.TaskResultMetadata && Object.keys(processTrackingFileData.Data.TaskChainedData.TaskResultMetadata).map((metadataKey, index) => (
                                                        <>
                                                            <div className="px-2 border font-bold">{ metadataKey }</div>
                                                            <div className="px-2 border">{ processTrackingFileData.Data.TaskChainedData.TaskResultMetadata[metadataKey] }</div>
                                                        </>
                                                    ))
                                                }

                                            </div>
                                            {
                                                Array.isArray(taskChainRows) && taskChainRows.length > 0
                                                    ?   <div className="mt-4 grid" style={{gridTemplateColumns: "auto auto 7rem"}}>
                                                            <div className="px-2 border font-bold">Task</div>
                                                            <div className="px-2 border font-bold">Task Chain File</div>
                                                            <div className="px-2 border font-bold text-center">Download</div>
                                                            { taskChainRows }    
                                                        </div>
                                                    :   null
                                            }
                                            <div className="flex justify-evenly my-2">
                                                <button className="inline bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => {navigator.clipboard.writeText(JSON.stringify(processTrackingFileData.Data, null, " ")).then(() => alert("Copied file contents to clipboard."));}}><FontAwesomeIcon  className="text-lg px-2" icon={faCopy} /> Copy File Contents</button>
                                            </div>
                                        </div>
                    }
                </div>
            </div>
        </div>
    );
}